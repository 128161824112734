<template>
  <div
    class="tw-h-full tw-w-full tw-rounded-md tw-shadow-sm tw-flex tw-flex-col tw-gap-6 tw-bg-transparent"
  >
    <!-- First Row -->
    <div class="tw-flex tw-flex-row tw-w-full tw-gap-4 tw-h-3/5">
      <!-- First Column - Two Cards -->
      <div class="tw-flex tw-flex-col tw-w-1/2 tw-h-full">
        <div class="tw-h-[calc(50%-0.25rem)] tw-mb-2">
          <router-link :to="{ name: 'customers' }">
            <InocuCard
              :title="t('Dashboard.Customers_4')"
              :trendingValue="0"
              :value="100"
            >
              <template #icon>
                <TotalCustomersIcon />
              </template>
              <template #value> {{ cardsData.totalCustomers }} </template>
            </InocuCard>
          </router-link>
        </div>
        <div class="tw-h-[calc(50%-0.25rem)]">
          <router-link :to="{ name: 'list-users' }">
            <InocuCard
              :title="t('Dashboard.ActiveUsers_6')"
              :trendingValue="0"
              :value="100"
            >
              <template #icon>
                <ActiveUsersIcon />
              </template>
              <template #value> {{ cardsData.totalActiveUsers }} </template>
            </InocuCard>
          </router-link>
        </div>
      </div>
      <!-- Second Column - One Full Height Card -->
      <div class="tw-w-1/2 tw-h-full">
        <router-link :to="{ name: 'orders' }">
          <InocuCardWithChart
            :title="t('Dashboard.TotalRequests_5')"
            :trendingValue="cardsData.totalRequestsComparison"
            dark
            :value="100"
            :chartSeries="cardsData.chartSeries"
          >
            <template #icon>
              <TotalRequestsIcon />
            </template>
            <template #value> {{ cardsData.totalRequests }} </template>
          </InocuCardWithChart>
        </router-link>
      </div>
    </div>
    <!-- Second Row -->
    <div class="tw-h-2/5">
      <router-link :to="{ name: 'projects' }">
        <InocuCard
          :title="t('Dashboard.TotalProjects_7')"
          :trendingValue="0"
          :cardBgSize="20"
          :value="100"
          dark
        >
          <template #icon>
            <TotalProjectsIcon />
          </template>
          <template #value> {{ cardsData.totalProjects }} </template>
        </InocuCard>
      </router-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import TotalRequestsIcon from "./icons/TotalRequestsIcon.svg";
import InocuCard from "@/components/shared/cards/InocuCard.vue";
import InocuCardWithChart from "@/components/shared/cards/InocuCardWithChart.vue";
import ActiveUsersIcon from "./icons/ActiveUsersIcon.svg";
import TotalProjectsIcon from "./icons/TotalProjectsIcon.svg";
import TotalCustomersIcon from "./icons/TotalCustomersIcon.svg";
import { useDashboardStore } from "@/stores/dashboard.store";
import { ref, onMounted, watch, type Ref } from "vue";
const { getAdminDashboardCards } = useDashboardStore();
const { t } = useI18n();

const isLoading = ref(true);
const props = defineProps<{
  startDate: string;
  endDate: string;
  customerId: number;
}>();

const cardsData: Ref<any> = ref({
  totalCustomers: 0,
  totalRequests: 0,
  totalRequestsComparison: 0,
  totalActiveUsers: 0,
  totalProjects: 0,
  chartSeries: [],
});
const fetchData = async () => {
  isLoading.value = true;
  const data = await getAdminDashboardCards(
    props.startDate,
    props.endDate,
    props.customerId
  );
  isLoading.value = false;
  cardsData.value.totalCustomers = data.total_customers;
  cardsData.value.totalRequests = data.total_requests;
  cardsData.value.totalRequestsComparison = data.total_requests_comparison;
  cardsData.value.totalActiveUsers = data.total_active_users;
  cardsData.value.totalProjects = data.total_projects;
  cardsData.value.chartSeries = data.five_days_overview;
};

onMounted(async () => {
  await fetchData();
});

watch(
  props,
  async () => {
    await fetchData();
  },
  { deep: true }
);
</script>
