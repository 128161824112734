<template>
  <AdminDashboard v-if="currentUser?.user_role.includes(UserRoles.ADMIN)" />

  <RolesDashboard v-else />
</template>

<script setup lang="ts">
import { useUserStore } from "@/stores/user.store";
import { storeToRefs } from "pinia";
import AdminDashboard from "./AdminDashboard/index.vue";
import RolesDashboard from "./RolesDashboard.vue";
import { UserRoles } from "@/_helpers/interfaces";

const { currentUser } = storeToRefs(useUserStore());
</script>
