<template>
  <div
    class="tw-rounded-md tw-h-full hover:tw-cursor-pointer tw-border-2 tw-border-transparent tw-transition-all tw-group"
    :class="{
      'tw-bg-[#DDF273B0] hover:tw-border-[#CEEC34] hover:tw-shadow-[0px_0px_10px_-8px_#CEEC34]':
        !props.dark,
      'tw-bg-[#23283B] hover:tw-border-[#CEEC34] hover:tw-shadow-[0px_0px_15.6px_-8px_#CEEC3426]':
        props.dark,
    }"
    :style="{
      backgroundImage: `url(${props.dark ? cardLogoDarkBg : cardLogoLightBg})`,
      backgroundSize: props.cardBgSize ? `${props.cardBgSize}%` : '40%',
      backgroundPosition: 'top right',
      backgroundRepeat: 'no-repeat',
    }"
  >
    <div class="tw-mx-3 tw-mt-2 tw-pt-2">
      <slot name="icon"></slot>
    </div>

    <!-- Text Content -->
    <div class="tw-flex tw-flex-col tw-mx-3 tw-gap-1">
      <p
        class="tw-font-normal tw-text-sm tw-my-0 tw-pt-1 tw-break-words"
        :class="{
          'tw-text-[#666666]': !props.dark,
          'tw-text-white': props.dark,
        }"
      >
        <slot name="title">
          {{ props.title }}
        </slot>
      </p>
      <div class="tw-flex tw-justify-between tw-items-center tw-m-0">
        <p
          :class="{
            'tw-text-[#666666]': !props.dark,
            'tw-text-white': props.dark,
          }"
          class="tw-text-2xl tw-m-0 tw-font-bold"
        >
          <slot name="value">
            {{ $props.value }}
          </slot>
        </p>
        <!-- Percentage change -->
        <div class="tw-flex tw-items-center" v-if="props.trendingValue">
          <div
            class="tw-flex tw-items-center tw-px-1 tw-py-1 tw-rounded-full tw-min-w-[44px] tw-h-4 tw-font-light tw-text-[8px]"
            :class="{
              'tw-text-red-500 tw-bg-red-100':
                props.trendingValue && props.trendingValue < 0,
              'tw-text-[#23283B] tw-bg-[#3AE94999]':
                props.trendingValue && props.trendingValue > 0,
            }"
          >
            <svg
              v-if="props.trendingValue && props.trendingValue > 0"
              width="11"
              height="12"
              viewBox="0 0 11 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.22599 4.49601L3.72225 4.49601L3.72225 6.99976"
                stroke="#23283B"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.22833 8.00202L3.75732 4.53101"
                stroke="#23283B"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <svg
              v-else-if="props.trendingValue && props.trendingValue < 0"
              width="11"
              height="11"
              viewBox="0 0 11 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.1967 5.19905L4.1967 7.7028L6.70044 7.7028"
                stroke="#E93A57"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.70294 4.19671L4.23193 7.66772"
                stroke="#E93A57"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            {{ Math.abs(props.trendingValue) }} %
          </div>
        </div>
      </div>
      <div
        class="tw-flex tw-w-full tw-justify-end"
        v-if="props.chartSeries && props.chartSeries.length > 0"
      >
        <p
          class="tw-text-[10px] tw-text-white tw-font-bold tw-mb-[1px] tw-pl-8"
        >
          5 DAY OVERVIEW
        </p>
      </div>
    </div>

    <!-- Static Chart -->
    <div
      class="tw-my-4 tw-h-[80px] tw-px-0 tw-relative tw-mb-[-2px] tw-mx-[-2px] tw-border-transparent group-hover:tw-border-2"
    >
      <div
        class="tw-flex tw-justify-between tw-h-full tw-full"
        v-if="props.chartSeries"
      >
        <div
          class="tw-flex tw-flex-col tw-h-full tw-w-1/6 tw-items-center tw-relative"
          v-for="(item, index) in props.chartSeries"
          :key="index"
        >
          <div
            class="tw-bg-gradient-to-b tw-from-[#CECED1] tw-to-[#69696B] tw-h-full tw-w-[1px] tw-absolute tw-bottom-0 tw-z-0"
          ></div>

          <div
            class="tw-bg-gradient-to-b tw-from-[#CECED1] tw-to-[#69696B] tw-w-full tw-z-10 tw-absolute tw-bottom-0"
            :class="{
              'tw-rounded-bl-md': index === 0,
              'tw-rounded-br-md': index === props.chartSeries.length - 1,
            }"
            :style="{
              height: `${item}%`,
            }"
          ></div>
        </div>
        <!-- <div class="tw-flex tw-flex-col tw-h-full tw-w-1/6 tw-items-center">
          <div
            class="tw-bg-gradient-to-b tw-from-[#CECED1] tw-to-[#69696B] tw-h-full tw-w-[1px]"
          ></div>
          <div
            class="tw-bg-gradient-to-b tw-from-[#CECED1] tw-to-[#69696B] tw-h-1/4 tw-w-full"
          ></div>
        </div>
        <div class="tw-flex tw-flex-col tw-h-full tw-w-1/6 tw-items-center">
          <div
            class="tw-bg-gradient-to-b tw-from-[#CECED1] tw-to-[#69696B] tw-h-full tw-w-[1px]"
          ></div>
          <div
            class="tw-bg-gradient-to-b tw-from-[#CECED1] tw-to-[#69696B] tw-h-5/6 tw-w-full"
          ></div>
        </div>
        <div class="tw-flex tw-flex-col tw-h-full tw-w-1/6 tw-items-center">
          <div
            class="tw-bg-gradient-to-b tw-from-[#CECED1] tw-to-[#69696B] tw-h-full tw-w-[1px]"
          ></div>
          <div
            class="tw-bg-gradient-to-b tw-from-[#CECED1] tw-to-[#69696B] tw-h-1/5 tw-w-full"
          ></div>
        </div>
        <div class="tw-flex tw-flex-col tw-h-full tw-w-1/6 tw-items-center">
          <div
            class="tw-bg-gradient-to-b tw-from-[#CECED1] tw-to-[#69696B] tw-h-full tw-w-[1px]"
          ></div>
          <div
            class="tw-bg-gradient-to-b tw-from-[#CECED1] tw-to-[#69696B] tw-h-3/5 tw-w-full tw-rounded-br-md"
          ></div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  title: string;
  value: string | number;
  trendingValue?: number;
  dark?: boolean;
  cardBgSize?: number;
  chartSeries?: number[];
}>();

console.log(props.chartSeries);

// Import the images
import cardLogoLightBg from "@/assets/icons/cardLogoLightBg.svg?url";
import cardLogoDarkBg from "@/assets/icons/cardLogoDarkBg.svg?url";
</script>
