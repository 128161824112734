<template>
  <div class="tw-flex tw-flex-col tw-h-full">
    <!-- Header -->
    <div class="tw-flex tw-justify-between tw-items-center tw-p-6">
      <h1 class="tw-text-xl tw-font-extralight">{{ t("Users.Users_14") }}</h1>
      <div class="actions">
        <button
          class="add-user-btn"
          @click="router.push({ name: 'create-user' })"
        >
          <span class="icon"
            ><svg
              width="16"
              height="16"
              viewBox="0 0 14 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.7918 11.875H8.4585"
                stroke="#23283B"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.625 13.0416V10.7083"
                stroke="#23283B"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.09351 6.84085C7.03518 6.83502 6.96518 6.83502 6.90101 6.84085C5.51268 6.79419 4.41018 5.65669 4.41018 4.25669C4.40434 2.82752 5.56518 1.66669 6.99434 1.66669C8.42351 1.66669 9.58434 2.82752 9.58434 4.25669C9.58434 5.65669 8.47601 6.79419 7.09351 6.84085Z"
                stroke="#23283B"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6.99395 13.2225C5.93228 13.2225 4.87645 12.9542 4.07145 12.4175C2.65978 11.4725 2.65978 9.93252 4.07145 8.99335C5.67561 7.92002 8.30645 7.92002 9.91061 8.99335"
                stroke="#23283B"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          {{ t("Users.CreateUser_1") }}
        </button>
        <div
          class="tw-group tw-flex tw-flex-row tw-space-x-2 tw-text-gray-500 tw-transition-colors tw-duration-300 tw-ease-in-out tw-text-nowrap tw-items-center"
        >
          <router-link
            class="tw-font-semibold tw-text-sm tw-text-gray-500 tw-border-b-2 tw-border-transparent tw-transition-colors tw-duration-300 tw-ease-in-out group-hover:tw-border-primary-300 group-hover:tw-text-primary-300"
            :to="{ name: 'list-users' }"
          >
            {{ t("Users.ViewAllUsers_47") }}
          </router-link>
          <OpenLinkIcon
            class="tw-transition-colors tw-duration-300 tw-ease-in-out group-hover:tw-text-primary-300"
          />
        </div>
      </div>
    </div>

    <!-- Table container with flex-1 to push pagination to bottom -->
    <div class="tw-flex-1">
      <InocuDataTable
        :headers="headers"
        :items="items"
        :rows-per-page="serverOptions.rowsPerPage"
        theme-color="#C3D744"
        table-class-name="customize-table"
        header-text-direction="left"
        body-text-direction="left"
        v-model:server-options="serverOptions"
        :server-items-length="usersListTotalCount"
        must-sort
        :loading="isLoading"
        hide-footer
      >
      </InocuDataTable>
    </div>

    <!-- Custom pagination at bottom -->
    <CustomPagination
      v-model:current-page="serverOptions.page"
      v-model:rows-per-page="serverOptions.rowsPerPage"
      :total-items="usersListTotalCount"
      :rows-items="[5, 10, 20]"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch, type Ref } from "vue";
import type { ServerOptions, Header } from "vue3-easy-data-table";
import OpenLinkIcon from "./icons/openLinkIcon.svg";
import { useRouter } from "vue-router";
import { useUserStore } from "@/stores/user.store";
import moment from "moment";
import { useI18n } from "vue-i18n";
import CustomPagination from "@/components/shared/CustomPagination.vue";
const { t } = useI18n();
const router = useRouter();
const { getUsersList } = useUserStore();
const customerId = ref(-1);
const usersListTotalCount = ref(0);
const isLoading = ref(false);
const headers: Header[] = [
  {
    text: t("Users.FirstName_3"),
    value: "first_name",
    sortable: true,
    width: 120,
  },
  {
    text: t("Users.LastName_12"),
    value: "last_name",
    sortable: true,
    width: 120,
  },
  {
    text: t("Global.Email_8"),
    value: "email",
    sortable: true,
    width: 100,
  },
  {
    text: t("Users.CreationDate_17"),
    value: "created_on",
    sortable: true,
  },
];

const items: Ref<any[]> = ref([]);
const serverOptions = ref<ServerOptions>({
  page: 1,
  rowsPerPage: 5,
  sortBy: "first_name",
  sortType: "asc",
});

const fetchUsers = async () => {
  let direction = serverOptions.value.sortType === "asc" ? "" : "-";
  let ordering = `${direction}${serverOptions.value.sortBy}`;
  isLoading.value = true;
  const result = await getUsersList({
    customer_id: customerId.value,
    active: true,
    page: serverOptions.value.page,
    page_size: serverOptions.value.rowsPerPage,
    ordering: ordering,
  });
  isLoading.value = false;
  items.value = result.results.map((user) => ({
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    created_on: moment(user.created_on).format("DD.MM.YYYY"),
    profile_picture: user.profile_picture,
  }));
  usersListTotalCount.value = result.count;
};
onMounted(async () => {
  customerId.value = +localStorage.getItem("selectedCustomerId")!;
  await fetchUsers();
});

watch(
  serverOptions,
  async () => {
    await fetchUsers();
  },
  { deep: true }
);
</script>
