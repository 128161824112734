import * as Sentry from "@sentry/vue";
import { createPinia } from "pinia";
import { createApp } from "vue";
import { configureApi } from "./_helpers/plugins/api-configuration";
import i18n from "./_helpers/plugins/i18n";
import App from "./App.vue";
import router from "./router";
// Importing styles
import "@vuepic/vue-datepicker/dist/main.css";
import "@zedobaia/zui/style.css";
import "bootstrap";
import "maz-ui/css/main.css";
import "vue-toast-notification/dist/theme-default.css";
import "vue3-easy-data-table/dist/style.css";
import "./assets/css/icomoon/style.css";
import "./assets/css/theme.css";
import "./assets/sass/main.scss";

// Importing plugins and components
import DateRange from "@/components/shared/date-range/DateRange.vue";
import VueCustomTooltip from "@adamdehaven/vue-custom-tooltip";
import { default as LegacyMultiSelect } from "@vueform/multiselect";
import VueDatePicker from "@vuepic/vue-datepicker";
import MazCheckbox from "maz-ui/components/MazCheckbox";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import MazSwitch from "maz-ui/components/MazSwitch";
import VueApexCharts from "vue3-apexcharts";
import Vue3EasyDataTable from "vue3-easy-data-table";

// Create Vue app instance
const app = createApp(App);

// Sentry Error Tracking Initialization
Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "localhost",
        import.meta.env.VITE_SENTRY_PROPAGATION_TARGET_REGEX,
      ],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.5, // Capture 50% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // Sample rate at 10%
  replaysOnErrorSampleRate: 1.0, // Sample rate at 100% when errors occur
});

// Register plugins
app.use(createPinia());
app.use(router);
app.use(i18n);
app.use(VueApexCharts);
app.use(VueCustomTooltip, {
  background: "#eef2f7",
  color: "#3f4456",
});

// Register global components
app.component("DatePicker", VueDatePicker);
app.component("InocuDataTable", Vue3EasyDataTable);
app.component("LegacyMultiSelect", LegacyMultiSelect);
app.component("PhoneNumberInput", MazPhoneNumberInput);
app.component("DateRange", DateRange);
app.component("MazSwitch", MazSwitch);
app.component("MazCheckbox", MazCheckbox);

// Configure API
configureApi();

// Mount the app
app.mount("#app");
