<template>
  <div
    class="tw-h-8 tw-text-secondary-700 tw-flex tw-flex-row tw-justify-end tw-items-center tw-rounded-md"
  >
    <div class="tw-w-80 tw-pl-2">
      <DateRange
        :initial-date="[startOfWeek(subWeeks(new Date(), 1)), new Date()]"
        @selectDate="handleDateRangeChange($event)"
        :clearSelectsEmptyRange="true"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import moment from "moment";
import { startOfWeek, endOfWeek, subWeeks } from "date-fns";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const selectedCustomerId = ref();

const updateFilters = () => {
  emit("update:filters", {
    customerId: selectedCustomerId.value,
    startDate: selectedDateRange.value.startDate,
    endDate: selectedDateRange.value.endDate,
  });
};

onMounted(async () => {
  const customer = localStorage.getItem("selectedCustomerId");
  selectedCustomerId.value = +customer!;

  updateFilters();
});

// define emit
const emit = defineEmits(["update:filters"]);

// date range selection
const selectedDateRange = ref<{ startDate: string; endDate: string }>({
  startDate: moment(startOfWeek(subWeeks(new Date(), 1))).format("YYYY-MM-DD"),
  endDate: moment(endOfWeek(subWeeks(new Date(), 1))).format("YYYY-MM-DD"),
});

const handleDateRangeChange = async (range) => {
  selectedDateRange.value = { ...range };
  updateFilters();
};
</script>
