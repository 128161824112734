<template>
  <div class="tw-flex tw-flex-row tw-space-x-8 tw-w-full">
    <div class="tw-w-10/12">
      <InocuBarChart
        :series="series"
        :xaxis="xaxis"
        :isLoading="isLoading"
        is-money
      >
        <template #title>
          {{ t("Statistics.PaymentVolumeTitle_19") }}
        </template>
      </InocuBarChart>
    </div>

    <div class="tw-w-2/12 tw-flex tw-flex-col tw-gap-y-4 tw-max-h-72">
      <InocuCard
        :title="t('Statistics.TotalPaymentVolume_20')"
        :value="totalPaymentsVolume"
        :trendingValue="totalPaymentsVolumeComparison"
      >
        <template #icon>
          <totalPaymentVolumeIcon />
        </template>
        <template #value
          >{{ formattedTotalPaymentsVolume.value
          }}<span class="tw-text-sm">{{
            formattedTotalPaymentsVolume.unit
          }}</span>
        </template>
      </InocuCard>

      <InocuCard
        :title="t('Statistics.TotalPaymentVolumeApproved_21')"
        :value="totalApprovedPaymentsVolume"
        :trendingValue="totalApprovedPaymentsVolumeComparison"
        dark
      >
        <template #icon>
          <approvedPaymentVolume />
        </template>
        <template #value
          >{{ formattedApprovedPaymentsVolume.value
          }}<span class="tw-text-sm">{{
            formattedApprovedPaymentsVolume.unit
          }}</span>
        </template>
      </InocuCard>
    </div>
  </div>
</template>

<script setup lang="ts">
import totalPaymentVolumeIcon from "../icons/totalPaymentVolumeIcon.svg";
import approvedPaymentVolume from "../icons/approvedPaymentVolume.svg";
import { ref, onMounted, watch, computed, type Ref } from "vue";
import InocuBarChart from "@/components/shared/charts/InocuBarChart.vue";
import { useI18n } from "vue-i18n";
import { useDashboardStore } from "@/stores/dashboard.store";
import { useFormatValue } from "@/_helpers/composables/formatting.composable";
import InocuCard from "@/components/shared/cards/InocuCard.vue";
const { formatCurrency } = useFormatValue();
const { getPaymentVolume } = useDashboardStore();

const isLoading = ref(true);
const { t } = useI18n();

const props = defineProps<{
  startDate: string;
  endDate: string;
  customerId: number;
}>();

const series: Ref<{ name: string; data: number[] }[]> = ref([
  {
    name: t("Statistics.TotalPaymentVolumeApproved_21"),
    data: [],
  },
  {
    name: t("Statistics.TotalPaymentVolume_20"),
    data: [],
  },
]);
const xaxis: Ref<string[]> = ref([]);
const totalPaymentsVolume = ref(0);
const totalApprovedPaymentsVolume = ref(0);
const totalPaymentsVolumeComparison = ref(0);
const totalApprovedPaymentsVolumeComparison = ref(0);

const formattedTotalPaymentsVolume: Ref<any> = computed(() => {
  return formatCurrency(totalPaymentsVolume.value, true);
});

const formattedApprovedPaymentsVolume: Ref<any> = computed(() => {
  return formatCurrency(totalApprovedPaymentsVolume.value, true);
});

const fetchData = async () => {
  isLoading.value = true;
  try {
    const data = await getPaymentVolume(
      props.startDate,
      props.endDate,
      props.customerId
    );
    series.value[0].data = data.approved_payments_volume;
    series.value[1].data = data.all_payments_volume;
    xaxis.value = data.dates;
    totalPaymentsVolume.value = data.total_payments_volume;
    totalApprovedPaymentsVolume.value = data.total_approved_payments_volume;
    totalPaymentsVolumeComparison.value = data.total_payments_volume_comparison;
    totalApprovedPaymentsVolumeComparison.value =
      data.total_approved_payments_volume_comparison;
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
};

onMounted(fetchData);
watch(() => props, fetchData, { deep: true });
</script>
