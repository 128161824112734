import axios from "axios";
import { defineStore } from "pinia";

import type {
  CustomerListQuery,
  CustomerListResponse,
} from "@/_helpers/interfaces/customers/customer.interface";

export const useCustomerStore = defineStore("customer", () => {
  // states

  // actions

  // Post Main Data in Create Customer
  const createMainData = async (data: any): Promise<any> => {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    try {
      const { data } = await axios.post(`/customers/`, formData);

      return data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

  // Get Main Data in Create Customer By Id
  const getMainDataById = async (id: any): Promise<any> => {
    try {
      const { data } = await axios.get(`/customers/${id}/`);
      return data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

  // Update Main Data in Create Customer
  const updateMainData = async (data: any, id: any): Promise<any> => {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }

    try {
      const { data } = await axios.patch(`/customers/${id}/`, formData);

      return data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

  // Post Assign Team in Create Customer
  const createAssignTeam = async (assignTeamData: any): Promise<any> => {
    try {
      const { data } = await axios.post(
        `/customers/user_customer_assignments/`,
        assignTeamData
      );

      return data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

  // Get Assign Team in Create Customer By Id
  const getAssignTeamById = async (options: any): Promise<any> => {
    try {
      const { data } = await axios.get(
        `/customers/user_customer_assignments/`,
        {
          params: options,
        }
      );
      return data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

  // Post Form Order in Create Customer
  const createFormOrder = async (orderData: any): Promise<any> => {
    try {
      const { data } = await axios.post(
        `/orders/customer_order_fields/`,
        orderData
      );
      return data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

  // Get Form Order in Create Customer By Id
  const getFormOrderById = async (options: any): Promise<any> => {
    try {
      const { data } = await axios.get(`/orders/customer_order_fields/`, {
        params: options,
      });
      return data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

  // Update Form Order in Create Customer
  const updateFormOrder = async (formData: any, id: any): Promise<any> => {
    try {
      const { data } = await axios.put(
        `/orders/customer_order_fields/${id}`,
        formData
      );

      return data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

  // Post Form Payment in Create Customer
  const createFormPayment = async (paymentData: any): Promise<any> => {
    try {
      const { data } = await axios.post(
        `/payments/customer_payment_fields/`,
        paymentData
      );

      return data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

  // Get Form Payment in Create Customer By Id
  const getFormPaymentById = async (options: any): Promise<any> => {
    try {
      const { data } = await axios.get(`/payments/customer_payment_fields/`, {
        params: options,
      });
      return data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

  // Update Form Payment in Create Customer
  const updateFormPayment = async (formData: any, id: any): Promise<any> => {
    try {
      const { data } = await axios.put(
        `/payments/customer_payment_fields/${id}`,
        formData
      );

      return data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

  // Post Legal in Create Customer
  const createLegal = async (formData: any, customerId: any): Promise<any> => {
    try {
      const { data } = await axios.patch(`/customers/${customerId}/`, formData);

      return data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

  // Get Legal in Create Customer By Id
  const getLegalById = async (id: any): Promise<any> => {
    try {
      const { data } = await axios.get(`/customers/${id}`);
      return data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

  // Get All Customers in Customers listing
  const getCustomers = async (options: any): Promise<any> => {
    try {
      const { data } = await axios.get(`/customers/`, {
        params: options,
      });
      return data;

      // if (page == null && pageSize == null) {
      //   const { data } = await axios.get(`/customers/?is_active=true`);
      //   return data.results;
      // } else {
      //   const { data } = await axios.get(
      //     `/customers/?page=${page}&page_size=${pageSize}&is_active=true`
      //   );
      //   return data;
      // }
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  const getCustomersOptions = async (): Promise<any> => {
    try {
      const { data } = await axios.get(`/customers/?is_active=true`);
      return data.results;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  // Delete Customer from Customers listing
  const deleteCustomer = async (deleteData: any): Promise<any> => {
    try {
      const { data } = await axios.delete(`/customers/${deleteData}/`);
      // const { data } = await axios.post(`/customers/deactivate/`, {
      //   customers: deleteData,
      // });
      return data;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  // Delete Customer from Customers listing in Bulk
  const deleteBulkCustomer = async (ids: any): Promise<any> => {
    try {
      const { data } = await axios.post(`/customers/bulk_delete/`, {
        customer_ids: ids,
      });
      return data;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  // Get Customer Average Spending Chart
  const getAverageSpendingChart = async (
    startDate: any,
    endDate: any,
    customerId: any
  ): Promise<any> => {
    try {
      const { data } = await axios.get(
        `/payments/average_spending_chart/?start_date=${startDate}&end_date=${endDate}&customer=${customerId}`
      );
      return data;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  // Post User in Create Customer
  const addUser = async (userData: any): Promise<any> => {
    try {
      const { data } = await axios.post(`/users/add_user/`, userData);

      return data;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

  // Get All Users in Create Customer
  const getUsers = async (admin: any = false): Promise<any> => {
    try {
      let url = `/users/`;
      if (admin) {
        url += `?admin=true`;
      } else if (admin === false) {
        url += `?admin=false`;
      }
      const { data } = await axios.get(url);

      return data.results;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

  const getCustomersList = async (
    queryParams: CustomerListQuery
  ): Promise<CustomerListResponse> => {
    try {
      const { data } = await axios.get(`/customers/`, { params: queryParams });

      return data;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  // Get Order Fields in Create Customer
  const getOrderFields = async (): Promise<any> => {
    try {
      const { data } = await axios.get(`/orders/order_form_fields/`);
      return data.results;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  // Get Payments Fields in Create Customer
  const getPaymentsFields = async (): Promise<any> => {
    try {
      const { data } = await axios.get(`/payments/payment_form_fields/`);
      return data.results;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  };

  return {
    createMainData,
    getMainDataById,
    updateMainData,
    createAssignTeam,
    getAssignTeamById,
    createFormOrder,
    getFormOrderById,
    updateFormOrder,
    createFormPayment,
    getFormPaymentById,
    updateFormPayment,
    createLegal,
    getLegalById,
    getCustomers,
    getCustomersOptions,
    deleteCustomer,
    deleteBulkCustomer,
    getAverageSpendingChart,
    addUser,
    getUsers,
    getOrderFields,
    getPaymentsFields,
    getCustomersList,
  };
});
