<template>
  <div class="tw-flex tw-flex-row tw-space-x-8 tw-w-full">
    <div class="tw-w-10/12">
      <InocuAreaChart
        :series="series"
        :xaxis="xaxis"
        dark
        :isLoading="isLoading"
        isMoney
      >
        <template #title>
          {{ t("Statistics.OrderBalanceTrend_18") }}
        </template>
      </InocuAreaChart>
    </div>

    <div class="tw-w-2/12 tw-flex tw-flex-col tw-gap-y-4">
      <InocuCard
        :title="t('Statistics.OrderBalance_17')"
        value=""
        :trendingValue="0"
        dark
      >
        <template #icon>
          <orderBalanceIcon />
        </template>
        <template #value>
          {{ totalOrderBalanceFormatted.value
          }}<span class="tw-text-sm">{{
            totalOrderBalanceFormatted.unit
          }}</span>
        </template>
      </InocuCard>
    </div>
  </div>
</template>

<script setup lang="ts">
import orderBalanceIcon from "../icons/orderBalanceIcon.svg";
import { ref, onMounted, watch, type Ref, computed } from "vue";
import InocuAreaChart from "@/components/shared/charts/InocuAreaChart.vue";
import InocuCard from "@/components/shared/cards/InocuCard.vue";
import { useI18n } from "vue-i18n";
import { useDashboardStore } from "@/stores/dashboard.store";
import { useFormatValue } from "@/_helpers/composables/formatting.composable";

const { t } = useI18n();
const { getOrderBalance } = useDashboardStore();
const { formatCurrency } = useFormatValue();

const props = defineProps<{
  startDate: string;
  endDate: string;
  customerId: number;
}>();

const isLoading = ref(true);
const totalOrderBalance = ref(0);
const totalOrderBalanceFormatted: Ref<any> = computed(() =>
  formatCurrency(totalOrderBalance.value, true)
);
const series: Ref<{ name: string; data: number[] }[]> = ref([
  {
    name: t("Statistics.OrderBalanceTrend_18"),
    data: [],
  },
]);
const xaxis: Ref<string[]> = ref([]);

const fetchData = async () => {
  try {
    const data = await getOrderBalance(
      props.startDate,
      props.endDate,
      props.customerId
    );
    series.value[0].data = data.historical_order_balance as number[];
    xaxis.value = data.dates;
    totalOrderBalance.value = data.total_order_balance;
  } catch (error) {
    console.error("Error fetching order balance:", error);
  } finally {
    isLoading.value = false;
  }
};

onMounted(fetchData);

watch(() => props, fetchData, { deep: true });
</script>
