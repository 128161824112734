<template>
  <div class="tw-flex tw-flex-col tw-h-full">
    <!-- Header -->
    <div class="tw-flex tw-justify-between tw-items-center tw-p-6">
      <h1 class="tw-text-xl tw-font-extralight">
        {{ t("CustomerManagement.Customers_2") }}
      </h1>
      <div class="actions">
        <button
          class="add-user-btn"
          @click="router.push({ name: 'create-customer' })"
        >
          <span class="icon"
            ><svg
              width="16"
              height="16"
              viewBox="0 0 14 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.68506 8.50917C7.59035 8.50917 8.32423 7.77529 8.32423 6.87C8.32423 5.96471 7.59035 5.23083 6.68506 5.23083C5.77978 5.23083 5.0459 5.96471 5.0459 6.87C5.0459 7.77529 5.77978 8.50917 6.68506 8.50917Z"
                stroke="black"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.71272 12.2833C9.71272 10.9242 8.35938 9.8158 6.68521 9.8158C5.01105 9.8158 3.65771 10.9183 3.65771 12.2833"
                stroke="black"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12.2498 7.79167C12.2498 10.8542 9.77067 13.3333 6.70817 13.3333C3.64567 13.3333 1.1665 10.8542 1.1665 7.79167C1.1665 4.72917 3.64567 2.25 6.70817 2.25C7.47234 2.25 8.2015 2.40166 8.8665 2.68166C8.79067 2.91499 8.74984 3.16 8.74984 3.41667C8.74984 3.85417 8.87234 4.26833 9.08817 4.61833C9.20484 4.81667 9.3565 4.99748 9.5315 5.14915C9.93984 5.52248 10.4823 5.75 11.0832 5.75C11.3398 5.75 11.5848 5.70915 11.8123 5.62749C12.0923 6.29249 12.2498 7.0275 12.2498 7.79167Z"
                stroke="black"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M13.4167 3.41671C13.4167 3.60337 13.3933 3.7842 13.3467 3.9592C13.2942 4.19254 13.2008 4.42004 13.0783 4.61837C12.7983 5.09087 12.3492 5.45836 11.8125 5.62753C11.585 5.70919 11.34 5.75004 11.0833 5.75004C10.4825 5.75004 9.94 5.52252 9.53167 5.14919C9.35667 4.99752 9.205 4.81671 9.08833 4.61837C8.8725 4.26837 8.75 3.85421 8.75 3.41671C8.75 3.16004 8.79083 2.91503 8.86667 2.6817C8.9775 2.34337 9.16417 2.04005 9.40917 1.78922C9.835 1.35172 10.43 1.08337 11.0833 1.08337C11.7717 1.08337 12.3958 1.38088 12.8158 1.85922C13.1892 2.27338 13.4167 2.82171 13.4167 3.41671Z"
                stroke="black"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.9527 3.40503H10.2144"
                stroke="black"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.0835 2.55334V4.29751"
                stroke="black"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          {{ t("CustomerManagement.CreateCustomer_5") }}
        </button>
        <div
          class="tw-group tw-flex tw-flex-row tw-space-x-2 tw-text-gray-500 tw-transition-colors tw-duration-300 tw-ease-in-out tw-text-nowrap tw-items-center"
        >
          <router-link
            class="tw-font-semibold tw-text-sm tw-text-gray-500 tw-border-b-2 tw-border-transparent tw-transition-colors tw-duration-300 tw-ease-in-out group-hover:tw-border-primary-300 group-hover:tw-text-primary-300"
            :to="{ name: 'customers' }"
          >
            {{ t("CustomerManagement.ViewAllCustomers_157") }}
          </router-link>
          <OpenLinkIcon
            class="tw-transition-colors tw-duration-300 tw-ease-in-out group-hover:tw-text-primary-300"
          />
        </div>
      </div>
    </div>

    <!-- Table container -->
    <div class="tw-flex-1">
      <InocuDataTable
        :headers="headers"
        :items="items"
        :rows-per-page="serverOptions.rowsPerPage"
        theme-color="#C3D744"
        table-class-name="customize-table"
        header-text-direction="left"
        body-text-direction="left"
        v-model:server-options="serverOptions"
        :server-items-length="customersListTotalCount"
        must-sort
        :loading="isLoading"
        hide-footer
      >
      </InocuDataTable>
    </div>

    <!-- Custom pagination at bottom -->
    <CustomPagination
      v-model:current-page="serverOptions.page"
      v-model:rows-per-page="serverOptions.rowsPerPage"
      :total-items="customersListTotalCount"
      :rows-items="[5, 10, 20]"
    />
  </div>
</template>

<script setup lang="ts">
import { useCustomerStore } from "@/stores/customer.store";
import moment from "moment";
import { onMounted, ref, watch, type Ref } from "vue";
import { useRouter } from "vue-router";
import type { ServerOptions } from "vue3-easy-data-table";
import { useI18n } from "vue-i18n";
import OpenLinkIcon from "./icons/openLinkIcon.svg";
import CustomPagination from "@/components/shared/CustomPagination.vue";
const router = useRouter();

const { getCustomersList } = useCustomerStore();
const { t } = useI18n();
const customersListTotalCount = ref(0);
const isLoading = ref(false);
const serverOptions = ref<ServerOptions>({
  page: 1,
  rowsPerPage: 5,
  sortBy: "name",
  sortType: "asc",
});

const headers = [
  {
    text: t("CustomerManagement.CustomerName_10"),
    value: "name",
    sortable: true,
  },
  {
    text: t("CustomerManagement.LegalName_12"),
    value: "legal_name",
    sortable: true,
  },
  {
    text: t("CustomerManagement.CreationDate_158"),
    value: "created_on",
    sortable: true,
  },
];

const items: Ref<any[]> = ref([]);

const fetchCustomers = async () => {
  let direction = serverOptions.value.sortType === "asc" ? "" : "-";
  let ordering = `${direction}${serverOptions.value.sortBy}`;
  isLoading.value = true;
  const result = await getCustomersList({
    page: serverOptions.value.page,
    page_size: serverOptions.value.rowsPerPage,
    ordering: ordering,
  });
  isLoading.value = false;
  items.value = result.results.map((customer) => ({
    name: customer.name,
    legal_name: customer.legal_name,
    created_on: moment(customer.created_on).format("DD.MM.YYYY"),
  }));
  customersListTotalCount.value = result.count;
};

onMounted(async () => {
  await fetchCustomers();
});

watch(
  serverOptions,
  async () => {
    await fetchCustomers();
  },
  { deep: true }
);
</script>
