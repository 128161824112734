<template>
  <div class="tw-flex tw-flex-col tw-gap-8">
    <DashboardFilter @update:filters="handleFiltersChange" />
    <div class="tw-flex tw-flex-row tw-gap-8">
      <div class="tw-w-[40%] tw-h-full">
        <DashboardCards
          v-if="currentFilters.customerId"
          :startDate="currentFilters.startDate"
          :endDate="currentFilters.endDate"
          :customerId="currentFilters.customerId"
        />
      </div>
      <div class="tw-w-full tw-h-full">
        <InocuBarChart
          :series="series"
          :xaxis="xaxis"
          :isLoading="isLoading"
          :height="400"
        >
          <template #title> </template>
        </InocuBarChart>
      </div>
    </div>
    <div class="tw-flex tw-flex-row tw-gap-8">
      <div class="tw-w-1/2">
        <div class="tw-h-full tw-w-full tw-rounded-md tw-shadow-sm tw-bg-white">
          <UserTable />
        </div>
      </div>
      <div class="tw-w-1/2">
        <div class="tw-h-full tw-w-full tw-rounded-md tw-shadow-sm tw-bg-white">
          <CustomersTable />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import DashboardFilter from "./DashboardFilter.vue";
import DashboardCards from "./DashboardCards.vue";
import InocuBarChart from "@/components/shared/charts/InocuBarChart.vue";
import UserTable from "./UserTable.vue";
import CustomersTable from "./CustomersTable.vue";
import "./table_style.css";
import { ref, type Ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useDashboardStore } from "@/stores/dashboard.store";

const { getNumberOfRequests } = useDashboardStore();

const { t } = useI18n();

const isLoading = ref(false);
const currentFilters = ref({
  startDate: "",
  endDate: "",
  customerId: 0,
});

const series: Ref<{ name: string; data: number[] }[]> = ref([
  {
    name: t("Statistics.TotalPaymentRequests_8"),
    data: [],
  },
  {
    name: t("Statistics.TotalOrderRequests_7"),
    data: [],
  },
]);
const xaxis: Ref<string[]> = ref([]);

const fetchData = async () => {
  isLoading.value = true;
  try {
    const data = await getNumberOfRequests(
      currentFilters.value.startDate,
      currentFilters.value.endDate,
      currentFilters.value.customerId
    );
    series.value[0].data = data.payments;
    series.value[1].data = data.orders;
    xaxis.value = data.dates;
  } catch (error) {
    console.error("Error fetching number of requests:", error);
  } finally {
    isLoading.value = false;
  }
};

const handleFiltersChange = async (filters: any) => {
  currentFilters.value = filters;
  await fetchData();
};
</script>
