<template>
  <div class="tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-3">
    <div class="tw-flex tw-items-center tw-gap-2">
      <span class="tw-text-sm tw-text-gray-700">{{
        t("Global.RowsPerPage_22")
      }}</span>
      <select
        v-model="localRowsPerPage"
        class="tw-rounded-md tw-border-gray-300 tw-py-1 tw-text-sm focus:tw-border-primary-300 focus:tw-ring-primary-300"
      >
        <option v-for="size in rowsItems" :key="size" :value="size">
          {{ size }}
        </option>
      </select>
    </div>

    <div class="tw-flex tw-items-center tw-gap-2">
      <span class="tw-text-sm tw-text-gray-700">
        {{ startIndex }}-{{ endIndex }} {{ t("Global.Of_23") }} {{ totalItems }}
      </span>
      <div class="tw-flex tw-gap-1">
        <button
          @click="handlePrevPage"
          :disabled="currentPage === 1"
          class="tw-min-w-[32px] tw-h-8 tw-rounded-full tw-flex tw-items-center tw-justify-center tw-border tw-border-gray-300 disabled:tw-opacity-50 hover:tw-bg-gray-50 hover:tw-transition-colors"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            color="currentColor"
            fill="none"
          >
            <path
              d="M15 6C15 6 9.00001 10.4189 9 12C8.99999 13.5812 15 18 15 18"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>

        <!-- Page numbers -->
        <div class="tw-flex tw-gap-1">
          <template v-for="pageNum in displayedPages" :key="pageNum">
            <button
              v-if="pageNum !== '...'"
              @click="handlePageClick(Number(pageNum))"
              :class="[
                'tw-min-w-[32px] tw-h-8 tw-rounded-full tw-px-2 tw-py-1 tw-text-sm',
                currentPage === pageNum
                  ? 'tw-bg-primary-300 tw-text-white'
                  : 'tw-border tw-border-gray-300 tw-text-gray-700 hover:tw-bg-gray-50',
              ]"
            >
              {{ pageNum }}
            </button>
            <span
              v-else
              class="tw-min-w-[32px] tw-h-8 tw-flex tw-items-center tw-justify-center tw-text-gray-500"
            >
              {{ pageNum }}
            </span>
          </template>
        </div>

        <button
          @click="handleNextPage"
          :disabled="currentPage >= totalPages"
          class="tw-min-w-[32px] tw-h-8 tw-rounded-full tw-flex tw-items-center tw-justify-center tw-border tw-border-gray-300 disabled:tw-opacity-50 hover:tw-bg-gray-50 hover:tw-transition-colors"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            color="currentColor"
            fill="none"
          >
            <path
              d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, watch } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  currentPage: number;
  rowsPerPage: number;
  totalItems: number;
  rowsItems: number[];
}>();

const emit = defineEmits<{
  "update:currentPage": [page: number];
  "update:rowsPerPage": [size: number];
}>();

const localRowsPerPage = computed({
  get: () => props.rowsPerPage,
  set: (value) => emit("update:rowsPerPage", value),
});

const totalPages = computed(() =>
  Math.ceil(props.totalItems / props.rowsPerPage)
);

const startIndex = computed(() =>
  props.totalItems === 0 ? 0 : (props.currentPage - 1) * props.rowsPerPage + 1
);

const endIndex = computed(() =>
  Math.min(props.currentPage * props.rowsPerPage, props.totalItems)
);

const displayedPages = computed(() => {
  const pages: (number | string)[] = [];
  const currentPage = props.currentPage;
  const totalPages = Math.ceil(props.totalItems / props.rowsPerPage);

  if (totalPages <= 7) {
    // Show all pages if total pages is 7 or less
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
  } else {
    // Always show first page
    pages.push(1);

    if (currentPage > 3) {
      pages.push("...");
    }

    // Show pages around current page
    for (
      let i = Math.max(2, currentPage - 1);
      i <= Math.min(totalPages - 1, currentPage + 1);
      i++
    ) {
      pages.push(i);
    }

    if (currentPage < totalPages - 2) {
      pages.push("...");
    }

    // Always show last page
    if (totalPages > 1) {
      pages.push(totalPages);
    }
  }

  return pages;
});

const handlePrevPage = () => {
  if (props.currentPage > 1) {
    emit("update:currentPage", props.currentPage - 1);
  }
};

const handleNextPage = () => {
  if (props.currentPage < totalPages.value) {
    emit("update:currentPage", props.currentPage + 1);
  }
};

const handlePageClick = (page: number) => {
  emit("update:currentPage", page);
};

// Reset to page 1 when rows per page changes
watch(localRowsPerPage, () => {
  emit("update:currentPage", 1);
});
</script>
